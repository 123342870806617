@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import 'variables';

.font-poppins {
  font-family: $font-family-poppins !important;
}

.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.text-underline {
  text-decoration: underline;
}

.bg-color-accent {
  background-color: $accent;
}

.text-color-primary, a.text-color-primary:hover {
  color: $primarytext;
  transition: all 0.2s ease-in-out;
}

.text-color-secondary, a.text-color-secondary:hover {
  color: $secondarytext;
  transition: all 0.2s ease-in-out;
}

.text-color-tertiary, a.text-color-tertiary:hover {
  color: $tertiary;
  transition: all 0.2s ease-in-out;
}

.text-color-accent, a.text-color-accent:hover {
  color: $accent;
}

.text-color-gray, a.text-color-gray:hover {
  color: $gray !important;
}
