@import 'variables';

.section-container {
  margin: 20px 0;
}
.section-container .section-item-body {
  border-radius: 12px;
}
.section-header-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.section-container.accordion .card {
  background: #ffffff;
  /* Gray / Cool Gray / 200 */
  border: 2px solid $secondary;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;
  margin: 20px 0;
}
.section-container.accordion .card .card-header {
  background: #ffffff;
  border-bottom: 0;
  padding: 0;
}
.section-header-container .section-name,
.section-header-container .section-name h2, 
.section-header-container .section-name h2 a {
  display: flex;
  align-items: center;
  font-family: $font-family-poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: $gray;
  text-decoration: none;
}
.section-header-container .section-name h3, 
.section-header-container .section-name h3 a {
  text-decoration: none;
}
.section-header-container .section-score {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 0 0 11px;
  font-size: 13px;
  font-weight: 600;
  font-family: $font-family-poppins;
  color: $gray;
}
.section-header-container .section-score span {
  padding-left: 12px;
}
.section-header-container .section-score::after {
  content: '+';
  font-size: 24px;
  padding: 0 10px;
  color: $accent;
}
.section-header-container .section-score.active::after {
  content: '-';
  font-size: 24px;
  padding: 0 10px;
  color: $accent;
}
.section-header-container .section-score .score {
  font-family: $font-family-poppins;
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: $accent;
}
.section-header-container .section-header {
  display: flex;
  flex-direction: row;
}
.section-header-container .section-header div {
  padding-left: 10px;
}
.section-header-container .section-progress-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  .section-progress,
  .section-progress-50,
  .section-progress-80,
  .section-progress-90,
  .section-progress-100 {
    flex-grow: 1;
    flex-direction: row;
  }

  .section-progress.progress,
  .section-progress-50.progress,
  .section-progress-80.progress,
  .section-progress-90.progress,
  .section-progress-100.progress {
    height: 8px;
    border-radius: 50px;
    background-color: rgba(216, 221, 222, 1);
  }

  .section-progress.progress .progress-bar {
    background: linear-gradient(
      90deg,
      rgba(35, 156, 182, 1) 0%,
      rgba(216, 221, 222, 1) 100%
    );
    border-radius: 50px;
    height: 8px;
  }

  .section-progress-50.progress .progress-bar {
    background: linear-gradient(
      90deg,
      rgba(35, 156, 182, 1) 30%,
      rgba(216, 221, 222, 1) 100%
    );
    border-radius: 50px;
    height: 8px;
  }

  .section-progress-80.progress .progress-bar {
    background: linear-gradient(
      90deg,
      rgba(35, 156, 182, 1) 50%,
      rgba(216, 221, 222, 1) 100%
    );
    border-radius: 50px;
    height: 8px;
  }

  .section-progress-90.progress .progress-bar {
    background: linear-gradient(
      90deg,
      rgba(35, 156, 182, 1) 80%,
      rgba(216, 221, 222, 1) 100%
    );
    border-radius: 50px;
    height: 8px;
  }

  .section-progress-100.progress .progress-bar {
    background: rgba(35, 156, 182, 1);
    border-radius: 50px;
    height: 8px;
  }
}

@media screen and (max-width: 450px) {
  .section-container.accordion .card {
    padding: 12px;
  }
}
