.card-modal {
  background-color: white;
  border-radius: 8px;
  padding: 1.2em;
}

.title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #121212;
  text-align: center;
}

.score-text-success {
  background-color: #dafff1;
  border-radius: 8px;
  color: #13b77b;
  padding: 0.6em;
}

.score-text-failure {
  background-color: #feeded;
  border-radius: 8px;
  color: #f0494a;
  padding: 0.6em;
}
