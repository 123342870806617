@import 'variables';

.sign-in-button-external {
  font-family: $font-family-poppins;
  font-weight: 500;
  color: #656565;
  width: 50%;
  background-color: white;
  border-radius: 12px;
  padding: 12px 12px;
  border: 2px solid $secondary;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.width-50 {
  width: 50% !important;
}

.form-wrapper {
  padding: 5% 5%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;

  .b-form-body {
    font-family: $font-family-poppins;
    border: 2px solid $accent;
    border-radius: 12px;

    .custom-form-group {
      margin-bottom: 0;
      border-radius: 0 !important;

      display: flex;
      background-color: white;
      flex-direction: column;
      height: 56px;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 2px solid $accent;

      input {
        background-color: transparent;
        border: none;
        height: 56px;

        padding-left: 16px !important;
        padding-right: 16px !important;

        border-radius: 12px !important;
      }

      input:focus {
        box-shadow: none !important;
        outline: auto;
        outline-color: #f89f64;
      }

      input::placeholder {
        color: $gray;
        opacity: 1;
        font-weight: 500;
      }
    }

    .custom-form-group:first-child {
      border-top: 0;
      border-top-left-radius: 12px !important;
      border-top-right-radius: 12px !important;
    }

    .custom-form-group:last-child {
      border-bottom: 0;
      border-bottom-left-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }

    .form-names-container {
      display: flex;
      flex-direction: row;

      .custom-form-group {
        flex: 1;
        border-right: 2px solid $accent;
        border-bottom: 2px solid $accent;

        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .custom-form-group:last-child {
        border-right: 0 !important;

        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 12px !important;
      }
    }
  }

  .check-box-wrapper-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;

    .check-box-check-container {
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        margin: 0 !important;
        padding-left: 12px;
      }

      input.form-check-input {
        margin-top: 0.5rem !important;
      }

      .custom-checkbox {
        padding-left: 0;
        margin-right: 12px;
      }
    }
  }

  .check-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;

    .check-box-check-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      label {
        color: $accent;
        font-weight: 500;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        margin: 0 !important;
      }

      input.form-check-input {
        margin-top: 0.5rem !important;
      }

      .custom-checkbox {
        padding-left: 0;

        margin-right: 12px;
      }
    }
  }
}

@media (min-width: 250px) {
  .form-wrapper {
    width: 100%;
  }

  .width-50 {
    width: 100% !important;
  }

  .sign-in-button-external {
    width: 100%;
  }
}

@media (min-width: 320px) {
  .form-wrapper {
    width: 90%;
  }
}

@media (min-width: 600px) {
  .form-wrapper {
    width: 75%;
  }

  .width-50 {
    width: 50% !important;
  }

  .sign-in-button-external {
    width: 50%;
  }
}

@media (min-width: 701px) {
  .form-wrapper {
    width: 62%;
  }
}

@media (min-width: 801px) {
  .form-wrapper {
    width: 55%;
  }
}

@media (min-width: 1025px) {
  .form-wrapper {
    width: 42%;
  }
}

@media (max-width: 1390px) {
  .form-wrapper {
    padding: 5% 0;
  }
}

@media (max-width: 740px) {
  .form-wrapper {
    min-height: 80vh;
  }
}

@media (min-width: 1024px) {
  .form-wrapper {
    min-height: 80vh;
  }
}

input[type='checkbox']:before {
  bottom: 0;
  border-radius: 0;
  border: 2px solid $accent;
}

input[type='checkbox']:checked:before {
  border-radius: 0;
}

.clear-btn {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.go-back-btn {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
