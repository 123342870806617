$primary: #cff0fc;
$secondary: #e8f6fc;
$tertiary: #ffffff;
$accent: #1c8097;
$gray: #696969;
$orange: #f89f64;
$primarytext: #696969;
$secondarytext: #cff0fc;

$font-family-poppins: 'Poppins', sans-serif;
