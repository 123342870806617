@import 'variables';

.video-description-header {
  display: flex;
  justify-content: flex-end;
}
.video-description-header .custom-rating li:last-child {
  margin-right: 0px;
}
.video-description-header .custom-rating li {
  font-size: 20px;
}
.video-description-container .video-description {
  /* Body/Regular/14px */
  display: flex;
  text-align: left;
  flex-direction: column;
  padding: 8px 12px;
  background: #ffffff;
  border: 2px solid $secondary !important;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 20px;
}

.video-description-container .video-description .description {
  text-align: left;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: $gray;
}
.section-home-container .video-title {
  text-align: left;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.help-container {
  .player-wrapper {
    margin-bottom: 20px;
  }
}
.player-wrapper {
  width: auto;
  height: auto;
}
.react-player,
.react-player__preview {
  padding-top: 56.25% !important; // Percentage ratio for 16:9
  position: relative !important; // Set to relative
}
.react-player > div,
.react-player__preview > div {
  position: absolute; // Scaling will occur since parent is relative now
}

.react-player__shadow {
  margin: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}
