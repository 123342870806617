@import 'variables';
.kc-container.accordion {
  .card {
    padding: 16px;
    border-radius: 12px;
    border: 2px solid $secondary !important;
  }
  .wizard-container {
    .card-wizard {
      padding: 0;
      border: none !important;
    }
  }
}
.knowledge-check-container {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background: var(--primary-cinnabar-100);
  border-radius: 12px;
  flex-wrap: wrap;

  &.warning {
    background: var(--tan-hide-100) !important;
  }
}
.knowledge-check-container .left-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  align-items: center;
}
.knowledge-check-container .left-container .title {
  padding-left: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  text-align: left;

  /* Gray / Cool Gray / 800 */

  color: #1f2937;
}
.knowledge-check-container .left-container .certification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* White / 500 */
  width: 56px;
  height: 56px;
  background: #ffffff;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.knowledge-check-container .text-container .title {
  font-size: 1.5rem;
  font-weight: bold;
}
.knowledge-check-container .text-container .subtitle {
  font-size: 1.2rem;
}
.knowledge-check-container .knowledge-check-start-btn {
  font-size: 1.2rem;
  font-weight: bold;
  width: 15%;
}
.knowledge-check-list-container {
  font-family: $font-family-poppins !important;
  text-align: left;
}

.knowledge-check-list-container .row {
  margin-left: -15px !important;
  margin-right: -15px !important;

  .wizard-footnote {
    text-align: right;
    margin-top: 1rem;
  }
}
