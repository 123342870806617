:root {
  --easter-blue-50: #f4fafb;
  --easter-blue-100: #e9f5f8;
  --easter-blue-200: #c8e6ed;
  --easter-blue-300: #a7d7e2;
  --easter-blue-400: #65bacc;
  --easter-blue-500: #239cb6;
  --easter-blue-600: #1c8097;
  --easter-blue-700: #1a7589;
  --easter-blue-800: #155e6d;
  --easter-blue-900: #114c59;
  --gray-color: #6b7280;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-800: #1f2937;

  --tan-hide-100: #fef5f0;
  --tan-hide-500: #f89f64;
  --primary-cinnabar-100: #feeded;
  --alert-complete-500: #34d399;

  --dark-black-800: #121212;
}
