@import 'variables';
.user-setting-menu-container {
  margin-top: 5px;
  padding: 24px;
  position: absolute;
  background-color: white;
  width: 326px;
  right: 2.3rem;
  z-index: 2;
  border: 2px solid #d8ddde;
  box-sizing: border-box;
  border-radius: 12px;
}

.user-menu-content > ul:first-child > li:first-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 2px solid #cde4ea;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .menu-item-title {
    padding-left: 1rem;
    padding-right: 1rem;

    color: #717c7c;
    font-size: 16px;
    font-family: $font-family-poppins;

    .course-arrow {
      padding-right: 1rem;
    }
    .course-arrow::after,
    .course-arrow::before {
      color: $accent !important;
    }
  }
}

.user-menu-content {
  border: 2px solid #cde4ea;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 16px 0;
  padding: 0 !important;
}

.user-certificate-setting-card:hover {
  border-color: transparent !important;
}

.btn-view-certificate {
  padding: 8px;
  height: auto;
}

.user-certificate-setting-card {
  min-height: initial;
  max-height: initial;
  border-radius: 0 8px 8px 0;
}

.user-setting-menu-container .user-name {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  color: $accent;
  line-height: 32px;
  margin-bottom: 4px;
}
.user-setting-menu-container .user-email {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: $gray;
  line-height: 20px;
  margin-bottom: 4px;
}
.user-setting-menu-container .user-menu-header {
  padding: 10px;
}
