@import './assets/font-awesome/css/font-awesome.min.css';
@import './styles/fonts';
@import './styles/variables';

body,
html {
  padding: 0;
  margin: 0;
}

a:hover,
a:focus {
  filter: brightness(95%);
}

body div {
  font-family: "Poppins", sans-serif;
}

.App {
  //text-align: center;
  // background: #f9fafb;
  background-color: white;
  bottom: 0;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu {
  padding: 0;
  list-style-type: none;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu.isFlatMenu {
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.menu-item {
  height: auto;
  min-height: 42px;
  max-height: 84px;
  color: white;
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  margin: 9px 0;

  &.is-course {
    background-color: $accent;
  }

  &.is-module {
    background-color: $primary;
  }

  &.is-section {
    background-color: $secondary;
    border: 1px solid $primary;

    .menu-item-title {
      color: $primary !important;

      .course-name,
      .course-arrow {
        color: $primarytext !important;
      }
    }
  }

  &.is-video {
    background-color: $tertiary !important;
    border: 1px solid $primary !important;

    .menu-item-title {
      color: $primary !important;

      .course-name,
      .course-arrow {
        color: $primarytext !important;
        font-weight: normal !important;
      }
    }
  }
}

.menu-item-title {
  font-weight: 600 !important;
  font-family: $font-family-poppins !important;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: relative;
  overflow-y: hidden;
}

.menu-item.expanded.isFlatMenu .menu-item-title {
  background-color: white;
  color: #282c34;
}

.menu-item.isFlatMenu {
  background-color: white;
}

.menu-item.isFlatMenu .menu-item-title {
  color: #282c34;
}

.menu-item.expanded .menu-item-title {
  color: #ffffff;
}

.menu-item.hasSubmenu>.menu-item-title {
  font-weight: bold;
}

.menu .menu .menu-item.hasSubmenu>.menu-item-title {
  font-weight: normal;
}

.menu-item.hasSubmenu.expanded.isModuleOpen {
  background-color: $primary !important;
}

.menu.isModuleOpen>.menu-item {
  padding: 8px 8px 8px 28px;
  height: auto;
}

.menu-item.hasSubmenu.isFlatMenu>.menu-item-title {
  /* Body/Regular / 16px */
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}

.course-name {
  width: calc(100% - 30px);
  text-decoration: none;
}

.course-name:hover {
  text-decoration: none;
}

.menu-item.is-course .course-name {
  color: #ffffff;
}

.menu-item.is-module .course-name {
  color: $primarytext !important;
}

.course-arrow {
  text-align: right;
  width: 30px;
  padding-right: 10px;
  box-sizing: border-box;
  content: '+';
  top: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  color: $gray;
}

.course-arrow:hover {
  text-decoration: none;
  color: $gray;
}

.menu-item.is-course .course-arrow {
  color: #ffffff;
}

.menu-item.is-course .course-arrow:hover {
  color: #ffffff;
}

.hasSubmenu>.menu-item-title .course-arrow::after {
  content: '+';
}

.expanded.hasSubmenu>.menu-item-title .course-arrow::after {
  content: '-';
}

.hasSubmenu>.menu-item-title::after {
  //content: " + ";
  //padding: 0 12px;
  //float: right;
  //font-size: 21px;
}

.expanded.hasSubmenu>.menu-item-title::after {
  //content: " — ";
  //padding: 0 12px;
  //float: right;
  //font-size: 21px;
}

.menu .menu {
  padding-left: 0;
  margin-left: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-no-border {
  color: #000;
  border: none !important;
  background: transparent !important;
}

.custom-button-default:hover {
  background: $accent !important;
  filter: brightness(90%);
}

.custom-button-default {
  /* Body/Semi Bold / 16px */
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: $accent !important;
  border-radius: 6px;
  border: 0px;
  padding: 12px;
  width: 100%;
  height: 48px;
  position: relative;
}

.half-width {
  width: 50% !important;
}

.custom-button-default.disabled {
  background: #0c7087;
}

.custom-button-white {
  /* Body/Semi Bold / 16px */
  background: $accent;
  /* Gray / Cool Gray / 200 */
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $tertiary;

  &.p-12 {
    padding: 12px !important;
  }
}

.custom-button-white.disabled {
  background: $tertiary;
  color: $gray;
}

.custom-button-white img {
  padding: 0 10px;
}

.custom-button-blue {
  /* Secondary / Eastern-Blue / 100 */
  width: 100%;
  background: $secondary;
  border-radius: 8px;
  border: 0px;
  padding: 8px 16px;

  /* Body/Semi Bold / 16px */

  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* Secondary / Eastern-Blue / 800 */

  color: $primarytext;

  &.sky {
    background-color: #ffffff !important;
    border: 1px solid $accent !important;
  }
}

.custom-button-danger {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f0494a;
  border-radius: 6px;
  border: 0px;
  padding: 16px;
}

.custom-label-text {
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #111827;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.custom-label-description {
  left: 80px;
  top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #1f2937;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 32px 12px;
  min-height: 50px;
  text-align: center;
  justify-content: center;
}

.custom-question-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  align-items: center;
  padding: 8px;
}

.custom-label-question {
  color: #1f2937;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.custom-label-question-screener {
  color: #1f2937;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0px !important;
}

.custom-label-question-number {
  color: #9ca3af;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.custom-button-back svg {
  margin-right: 10px;
}

.custom-button-back:hover {
  background-color: #f6f5f5;
}

.custom-button-back {
  /*height: 48px;*/
  left: 130px;
  right: 385px;
  top: 201px;

  /* Gray / Cool Gray / 100 */

  /*background: #f3f4f6;*/
  background: #fff;
  /* Black / Dark Black / 800 */

  border: 1px solid #121212;
  box-sizing: border-box;
  border-radius: 6px;

  padding: 11px 25px;
}

.custom-question-answers.completed {
  opacity: 0.4;
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}

.custom-question-answers.completed.open {
  opacity: 0.4;
  max-height: 1500px;
  transition: max-height 0.25s ease-in;
}

.custom-question-answers {
  padding: 0px;
  position: static;
  left: 0px;
  top: 0px;
  background: transparent;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
}

.gray-link {
  margin-top: 1em;
  color: $gray !important;
  text-decoration: underline;
  font-size: 16px;
}

.gray-link:hover {
  filter: brightness(135%);
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.custom-submit-container .custom-button-default {
  width: 200px !important;
}

.custom-submit-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-submit-container-modal {
  justify-content: flex-end;
}

.custom-submit-button:hover {
  background: rgb(241, 137, 67);
}

.custom-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-bottom: 0;
  cursor: pointer;
}

.custom-submit-button-retake {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-left: 10px;
}

.custom-submit-button-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
}

.modal-success-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #dafff1;
  border-radius: 8px;
}

.modal-success-number-text {
  text-align: center;

  color: #13b77b;
}

.modal-fail-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #feeded;
  border-radius: 8px;
}

.modal-fail-number-text {
  text-align: center;

  color: #f0494a;
}

.modal-success-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  text-align: center;
  /* gray/900 */
  color: var(--gray-900);
}

.modal-container-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  position: static;
}

.custom-submit-button-text {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: flex-end;
  color: #ffffff !important;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.custom-submit-button-text-disabled {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: flex-end;
  color: grey !important;
  margin-bottom: 0 !important;
  cursor: default;
}

.custom-submit-button-disabled {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: flex-end;
  color: #ffffff !important;
  margin-bottom: 0 !important;
  background-color: grey;
  cursor: default;
}

.custom-label-button-radio {
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: flex-end;
  position: static;
  width: 68px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

.screener-question.col {
  padding: 0 !important;
}

@media screen and (max-width: 765px) {

  .custom-label-question-screener {
    margin-bottom: 1em;
  }

  .screener-question.col {
    flex: 1 1 auto !important;
  }

  .screener-question .custom-question-container .radio {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .screener-question .custom-label-description {
    width: 55% !important;
    align-items: center !important;
    min-height: 40px !important;
    text-align: left;
    justify-content: left;
  }

  .screener-question .custom-label-button-radio {
    width: 20% !important;
    border-radius: 6px !important;
    text-align: left;
    justify-content: left;
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .knowledge-check-list-container .row {
    flex-wrap: nowrap;
  }

  .screener-question .custom-label-description {
    margin: 0 4px 12px !important;
  }

}

.screener-question .custom-question-container {
  padding: 0;
}

.screener-question .custom-question-container .radio {
  width: 100%;
}

.screener-question .custom-label-button-radio {
  background: #e8f6fc;
  border: none;
  border-top: 1px solid #cff0fc;
  border-bottom: 1px solid #cff0fc;
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 0;
}

.custom-question-answers .screener-question:first-child .custom-label-button-radio {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #cff0fc;
}

.custom-question-answers .screener-question:last-child .custom-label-button-radio {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #cff0fc;
}

.screener-question .custom-label-button-radio input[type='radio'] {
  margin: 0 auto 7px;
}

input[type='radio'] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type='radio']:before {
  content: '';
  visibility: visible;
  content: '';
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid $accent;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type='radio']:checked:before {
  background-color: $accent;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid $accent;
  box-sizing: border-box;
  border-radius: 50px;
}

.custom-radio-accent input[type='radio']:checked:before {
  background-color: $accent !important;
  border: 1.5px solid $accent !important;
}

input[type='checkbox'] {
  position: relative;
  margin-left: 5px;
  width: 18px;
  height: 20px;
}

input[type='checkbox']:before {
  content: '';
  visibility: visible;
  content: '';
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
  top: -2px;
  margin-right: 1em;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type='checkbox']:checked:before {
  background-color: $accent;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid $accent;
  box-sizing: border-box;
  border-radius: 6px;
}

.custom-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-spinner-container span {
  padding-left: 5px;
}

.btn-back span {
  margin-left: 15px;
}

.btn-back {
  border: none;
  background-color: transparent;
}

.bg-transparent {
  background: transparent !important;
}

.loading {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.form-control {
  padding: 0 0.75rem !important;
}

@media screen and (max-width: 450px) {
  .card-body {
    padding: 0.8rem !important;
  }
}

.form-check-label {
  padding-left: 0.75rem;
}

.react-player__preview {
  min-height: calc(30vw);
  margin-bottom: 6px;
}

.multiline-ellipse {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  /*font-weight: bold;*/
  font-size: 1em;
  line-height: 1.4;
}

.font-inter {
  font-family: Inter !important;
}

.qrCode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}

.border-top-2 {
  border-top: 2px solid $secondary;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.border-bottom-2 {
  border-bottom: 2px solid $secondary;
}

// search
.search-video-title::before {
  content: '-';
  color: $accent;
  font-weight: 600;
  margin-right: 0.5rem;
}

.text-color-primary:hover {
  &.search-video-title {
    color: $accent !important;
  }
}

// org graphic container
#org-graphic-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  margin-top: 2rem !important;
}

#org-graphic-container img {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

// Handle height with flexbox
.flex-1-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;

  &.row-container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .flex-col {
    overflow-y: auto;
  }
}

@media screen and (max-width: 767px) {
  .flex-1-container {
    &.sm-column-container {
      flex-direction: column !important;
      justify-content: unset !important;
      flex-wrap: wrap !important;
    }
  }
}

.custom-modal-app {
  height: 95vh;
  overflow: hidden;

  .modal-content {
    height: 90vh;
    overflow-x: hidden;

    .flex-1-container {
      overflow-x: hidden;
    }
  }

  .footer-cnt {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

.no-certificate {
  margin: 0 !important;

  li.menu-item {
    border: 0 !important;
  }
}

.video-favorite-container {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
}

/** Video Rating **/

.video-rate-up,
.video-rate-down {
  color: $accent !important;
  margin-left: 1em;
  font-size: 1.1rem;
  cursor: pointer;
}

.video-rate-up:hover,
.video-rate-down:hover {
  filter: brightness(0.75);
}

.like-section {
  color: rgb(105, 105, 105) !important;
}

.max-width {
  width: 100%;
}

.wizard-navigation {
  display: none !important;
}

.card-wizard .card-header {
  display: none !important;
}

.card-wizard .card-footer {
  border-top: none !important;
  background-color: #fff !important;
}