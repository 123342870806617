@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter',
    'DM Sans' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

.custom-scrollbar ::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.alert-bounce-warning .icon {
  color: #fff;
}

.alert-bounce-warning .icon {
  background: #f89f64;
  border-radius: 6px;
  margin-right: 12px;
  padding: 8px;
}
.alert-bounce-warning {
  background: #fef5f0 !important;
  border: 1px solid #f89f64 !important;
}

.alert-bounce {
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

input[type='checkbox'] + label {
  cursor: pointer;
}

.separate > input {
  margin-right: 15px;
}

.bg-gray {
  background-color: #e1e1e1 !important;
}
.rc-rate-star:last-child {
  margin-right: 2px !important;
}
.custom-rating {
  margin-right: 14px !important;
}
.rc-rate-star-first,
.rc-rate-star-second {
  color: #757575 !important;
  font-size: 24px;
}
.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: #000000 !important;
  -webkit-text-fill-color: #f89f64;
  -webkit-text-stroke: 2px;
}
.text-black {
  color: #000 !important;
}

.inactive-video {
  background-color: #e1e1e1 !important;
}

.inactive-video .course-name {
  color: gray !important;
}
